<script>
import { mapActions } from "vuex";
import MainButton from "@/components/helpers/MainButton";
import MainInput from "@/components/helpers/MainInput.vue";

export default {
  components: {
    MainButton,
    MainInput,
  },

  data() {
    return {
      promoCode: "",
      loading: false,
      error: null,
    };
  },

  methods: {
    ...mapActions({
      setPromoСode: "auth/setPromoСode",
      checkUser: "auth/checkUser",
    }),

    sendPromoCode() {
      if (this.promoCode && !this.loading) {
        this.loading = true;

        this.setPromoСode(this.promoCode)
          .then(() => {
            this.loading = false;

            this.checkUser().then(() => {
              this.$emit("nextStep");
            });
          })
          .catch((error) => {
            this.loading = false;

            switch (error.response.status) {
              case 400:
                switch (error.response.data.message) {
                  case "promocode.not_found":
                    this.error = "Промокод недоступен или не существует";
                    break;
                  default:
                    this.error = "Неизвестная ошибка";
                }
                break;
              case 422:
                this.error = "Введите корректный промокод";
                break;
              default:
                this.error = "Неизвестная ошибка";
            }

            setTimeout(() => {
              this.error = null;
            }, 5000);
          });
      }
    },
  },
};
</script>

<template>
  <form class="registration-promo-code" @submit.prevent="sendPromocode">
    <h2 class="registration-promo-code__title">Промокод</h2>
    <p class="registration-promo-code__description">
      Промокод — это реферальный код приглашения, который позволяет партнерам RC
      Group зарабатывать.
    </p>

    <div class="registration-promo-code__wrapper">
      <main-input
        v-model="promoCode"
        label="Промокод"
        :error="!!error"
        :error-message="error"
      />
    </div>

    <div class="registration-promo-code__button">
      <main-button
        title="Далее"
        color="gold"
        :disabled="!promoCode"
        @click="sendPromoCode"
      />
    </div>
  </form>
</template>

<style lang="scss" scoped>
.registration-promo-code {
  width: 547px;
  padding: 48px;
  background: $background-white;

  &__title {
    @include title-3;
    color: $dark-primary;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    // > img {
    //   margin: 0 0 0 $space-s;
    // }
  }
  &__description {
    @include body-1;
    margin: $space-m 0 $space-xl;
    color: $dark-primary;
  }

  &__wrapper {
    margin: 0 0 $space-m;
  }

  &__error {
    font-size: 12px;
    line-height: 140%;
    color: $red;
    margin: 0 0 0 8px;
  }

  &__button {
    margin: $space-s 0 0;
    width: 300px;
  }
}
</style>
