<script>
import { mapState } from "vuex";
import RegistrationEmail from "@/components/registration/RegistrationEmail";
import RegistrationPromoCode from "@/components/registration/RegistrationPromoCode";

export default {
  name: "Registration",

  components: {
    RegistrationEmail,
    RegistrationPromoCode,
  },

  data() {
    return {
      step: "email",
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    setStep() {
      if (!this.user.email) {
        this.step = "email";
        return;
      }

      if (!this.user.promocode) {
        this.step = "promocode";
        return;
      }

      location.href = "/";
    },
  },

  created() {
    this.setStep();
  },
};
</script>

<template>
  <div class="registration">
    <registration-email v-if="step === 'email'" @nextStep="setStep" />

    <registration-promo-code v-if="step === 'promocode'" @nextStep="setStep" />
  </div>
</template>

<style lang="scss">
.registration {
  width: 100%;
  height: 100dvh;
  background: url("../assets/images/registration_background.png") no-repeat
    $background-grey right bottom;
  background-size: 800px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1240px) {
    background-size: contain;
  }
}
</style>
