<script>
import { mapActions } from "vuex";
import MainButton from "@/components/helpers/MainButton";
import MainInput from "@/components/helpers/MainInput.vue";

export default {
  components: {
    MainButton,
    MainInput,
  },

  data() {
    return {
      email: "",
      loading: false,
      error: null,
    };
  },

  methods: {
    ...mapActions({
      validateEmail: "auth/validateEmail",
      checkUser: "auth/checkUser",
    }),

    sendEmail(email) {
      if (this.emailValid && !this.loading) {
        this.loading = true;

        this.validateEmail(email)
          .then(() => {
            this.loading = false;

            this.checkUser().then(() => {
              this.$emit("nextStep");
            });
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.data.message === "person.exists") {
              this.error = "Эта почта уже зарегистрирована";
            } else {
              this.error = "Неизвестная ошибка";
            }

            setTimeout(() => {
              this.error = null;
            }, 5000);
          });
      }
    },
  },

  computed: {
    emailValid() {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(this.email);
    },
  },
};
</script>

<template>
  <form class="registration-email" @submit.prevent="sendEmail(email)">
    <h2 class="registration-email__title">Дополните данные</h2>
    <p class="registration-email__description">
      Укажите действительный адрес вашей электронной почты, он понадобится для
      нашего с вами общения.
    </p>

    <div class="registration-email__wrapper">
      <main-input
        v-model="email"
        type="email"
        label="Почта"
        :error="!!error"
        :error-message="error"
      />
    </div>

    <div class="registration-email__button">
      <main-button
        title="Далее"
        color="gold"
        :disabled="!emailValid"
        @click="sendEmail(email)"
      />
    </div>
  </form>
</template>

<style lang="scss" scoped>
.registration-email {
  max-width: 547px;
  width: 100%;
  padding: 48px;
  background: $background-white;

  @media (max-width: 475px) {
    padding: 24px;
  }

  &__title {
    @include title-3;
    color: $dark-primary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__description {
    @include body-1;
    margin: $space-m 0 $space-xl;
    color: $dark-primary;
  }

  &__wrapper {
    margin: 0 0 $space-m;
  }

  &__error {
    font-size: 12px;
    line-height: 140%;
    color: $red;
    margin: 0 0 0 8px;
  }

  &__button {
    margin: $space-s 0 0;
  }
}
</style>
